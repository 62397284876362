.PurchaseCard-Container {
    position: relative;
    text-align: center;
    color: white;
    background-color: black;
    font-family: 'Open Sans', sans-serif;
    border-radius: 10px;
    height: 200px;
    width: 100%;
}

.PurchaseCard-Image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    opacity: 0.4;
    object-fit: cover;
    object-position: center;
}

.PurchaseCard-Container:hover {
    opacity: 0.8;
}

.PurchaseCard-Bottom-Left {
    position: absolute;
    bottom: 3px;
    left: 10px;
}

.PurchaseCard-Title {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: white;
    text-align: left;
}

.PurchaseCard-Description {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: white;
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .PurchaseCard-Title {
        font-size: 12px;
    }

    .PurchaseCard-Description {
        font-size: 10px;
    }

    .PurchaseCard-Container {
        height: 150px;
    }
}