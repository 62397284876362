.CustomerFitness-Collapse-Panel {
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
}

.Customer-Fitness-Main-Div {
    font-family: "Open Sans";
}

.CustomerFitness-Card {
    height: 225px;
    width: 100%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    border-radius: 20px;
    background-color: white;
}

.CustomerFitness-Card:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    background-color: rgb(235, 235, 235);
}


.CustomerFitness-Card-Title {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 25px;
    font-weight: bold;
}

.Customer-Fitness-Section-Title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
}

.Customer-Fitness-PurchaseCard-Row {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    .Customer-Fitness-Section-Title {
        font-size: 15px;
    }
}