.UserProgram-Badge {
    height: 50px;
}

.UserProgram-Button-Completed {
    background-color: green;
    color: white;
    border-radius: 20px;
}

.UserProgram-Day-Complete-Div {
    line-height: 100%;
    height: 100%;
    text-align: center;
}

.UserProgram-Day-Complete {
    font-size: 35px;
    color: green;
    display: inline-block;
    vertical-align: middle;
}

.ProgramWorkout-Exercise-Video {
    width: 100%;
    min-height: 300px;
    object-position: center;
    object-fit: cover;
}

.UserProgram-Save-Workout {
    background-color: black;
    color: white;
    border-radius: 10px;
}

.ProgramWorkout-Exercise {
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
}

.ProgramWorkout-ExerciseDetails-Button {
    background-color: black;
    color: white;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ProgramWorkout-Modal-Content {
    margin-top: 20px;
}