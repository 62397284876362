@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

.Banner2-Main-Col {
    background-color: white;
    font-family: "Open Sans";
}

.Banner2-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    opacity: 0.6;
}

.Banner2-Main-Div {
    background-color: black;
    position: relative;
    text-align: center;
}

.Banner2-Text-Div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.Banner2-Name {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 300;
    font-size: 70px;
    line-height: 75px;
    margin-bottom: 10px;
    text-align: center;
    word-wrap: break-word;
}

.Banner2-BusinessStatement {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 300;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    min-width: 700px;
    word-wrap: break-word;
}

@media only screen and (max-width: 600px) {
    .Banner2-BusinessStatement {
        min-width: 0px;
    }
}