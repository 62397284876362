.LiveClass-Image {
    max-width: 100%;
    height: 150px;
    width: 200px;
    object-fit: cover;
    border-radius: 20px;
}

.LiveClass-Row {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px;
    color: black;
    margin-top: 20px;
    margin-bottom: 20px;
    border: solid;
}

.LiveClass-Row:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 576px) {
    .LiveClass-Image {
        height: 200px;
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
}