.Navbar3-Main-Col {
    background-color: white;
    padding: 10px;
    font-family: "Open Sans";
}

.Navbar3-Second-Bar {
    background-color: #E9E9E9;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Navbar3-Large-Link-Text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: black;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 0px;
    padding-right: 0px;
}

.Shopping-Cart-Float {
    position:fixed;
    padding: 5px;
	bottom:20px;
	right:20px;
	border-radius:50px;
	text-align:center;
    background-color: white;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Shopping-Cart-Float:hover {
    background-color: white;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}