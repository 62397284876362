@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
.Navbar1-Main-Row {
    background-color: white;
    padding: 10px;
    font-family: "Open Sans";
}

.Navbar1-Large-Logo {
    max-width: 100px;
    max-height: 60px;
    object-fit: contain;
    margin-right: 10px;
}

.Navbar1-Large-BusinessName {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
}

.Navbar1-Large-Link-Text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: black;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 0px;
    padding-right: 0px;
}

.Navbar1-React-Social-Icon {
    max-height: 25px;
    max-width: 25px;
}

.Navbar1-Large-Login-Button {
    border-radius: 10px;
    background-color: black;
    color: white;
    border-color: black;
    padding: 5px 15px 5px 15px;
}

.Navbar1-Large-Login-Button:hover {
    opacity: 80%;
    cursor: pointer;
}

.Navbar1-Shopping-Cart {
    font-size: 25px;
    color: black;
}

.Navbar-Small-Logo-Name {
    text-align: center;
}

.Navbar1-Small-Logo {
    max-height: 50px;
    max-width: 100%;
}

.Navbar1-Small-BusinessName {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
}

.Navbar-Drawer {
    min-width: 300px;
}

.Navbar-Small-Drawer-Link {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {
    .Navbar1-Large-BusinessName {
        font-size: 13px;
    }

    .Navbar1-Large-Link-Text {
        text-align: center;
        font-size: 15px;
        font-weight: 300;
    }
}