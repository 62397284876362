.ClassesHeader-Background {
    background-position: center;
}

.ClassesHeader-Image {
    height: 175px;
    width: 175px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ClassesHeader-Col {
    text-align: center;
}

.ClassHeader-Title {
    margin-bottom: 1px;
}