.CarouselCard-Phros-Div {
    max-height: 300px;
    width: 100%;
    border-radius: 10px;
}

.CarouselCard-Image {
    height: 150px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.CarouselCard-Button {
    border-radius: 10px;
    color: white;
    background-color: black;
    margin: 5px;
    border: black;
}

.CarouselCard-Text {
    padding: 5px;
}