.Video-Card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    min-height: 400px;
    border-radius: 10px;
    height: 100%;
}

.Video-Card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
}

.Video-Card-Container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Video-Card-Image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

.Video-Card-Button {
    padding-top: 20px;
}

.Video-Card-Modal {
    width: 800px;
}

.Video-Card-Modal-Image {
    min-height: 300px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Video-Card-Tag {
    border-radius: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    margin-bottom: 5px;
}

.Video-Card-Space {
    padding-top: 5px;
    padding-bottom: 5px;
}