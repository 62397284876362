.FAQ-Collapse-Custom {
    font-size: 20px;
}

.FAQ-Collapse-Custom-Text {
    font-size: 15px;
}

.FAQ-Collapse {
    border-radius: 20px;
}