.ProgramWorkout-Main-Div {
    max-width: 100vw;
}

.ProgramWorkout-Exercise-Link {
    padding-top: 5px;
    padding-bottom: 5px;
}

.ProgramOverview-Button-Danger {
    border-radius: 10px;
}

.ProgramOverview-Button-Complete {
    background-color: #39C16C;
    border-color:  #39C16C;
    border-radius: 10px;
}