.ProgramOverview-Main-Div {
    max-width: 100vw;
}

.ProgramOverview-Row {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ProgramOverview-Input {
    border-radius: 10px;
    width: 100%;
}

.ProgramOverview-Input-Subtext {
    font-size: 13px;
}

.ProgramOverview-Button {
    background-color: black;
    border-color: black;
    color: white;
    border-radius: 10px;
}

.ProgramOverview-Calendar-Workout-Button {
    background-color: black;
    border-color: black;
    color: white;
    border-radius: 10px;
    height: 40px;
}

.ProgramOverview-Calendar-Workout-Button:active:hover {
    background-color: black;
    border-color: black;
    color: white;
    border-radius: 10px;
    height: 40px;
}

.ProgramOverview-Button-Complete:active:hover {
    background-color: #39C16C;
    border-color:  #39C16C;
    border-radius: 10px;
}