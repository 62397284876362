.Card-Icon {
    font-size: 60px;
}

.Card {
    padding: 20px 20px 30px 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
    background-color: white;
    border-radius: 10px;
}

.Card-Center {
    text-align: center;
}