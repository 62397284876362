.FAQ1-Row {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Open Sans";
}

.FAQ1-Question {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.FAQ1-Question-Container {
    background: #F8F8F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
}

.FAQ1-Answer {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding: 20px;
}