.CheckoutCard-Image {
    min-height: 150px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.Checkout-Total-Cost {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 20px;
    border-radius: 20px;
}

.Checkout-Row {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Checkout-Input {
    border-radius: 10px;
}

.Checkout-Button {
    border-radius: 10px;
    background-color: black;
    color: white;
}

.Checkout-Button:active:focus:hover {
    border-radius: 10px;
    background-color: black;
    color: white;
}

.Checkout-Promo-Applied-Products {
    font-size: 13px;
}

.Checkout-Not-Charged-Yet {
    font-size: 13px;
    text-align: center;
    margin-top: 5px;
}