@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

.Banner3-Main-Col {
    background-color: white;
    font-family: "Open Sans";
    padding: 20px;
}

.Banner3-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Banner2-Main-Div {
    background-color: black;
    position: relative;
    text-align: center;
}

.Banner2-Text-Div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.Banner3-Name {
    text-align: left;
    color: black;
    font-style: italic;
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.Banner3-BusinessStatement {
    text-align: left;
    color: black;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    word-wrap: break-word;
}

.Banner3-Input {
    border-radius: 10px;
    padding: 7px;
}

.Banner3-Contact-Button {
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: black;
    color: white;
    height: 100%;
    width: 100%;
    padding: 7px;
}

.Banner3-Contact-Button:hover {
    cursor: pointer;
}