.Logo-Image {
    height: 60px;
    max-height: 100%;
}

.Logo-Text {
    letter-spacing: 5px;
    font-size: 30px;
    font-weight: 200;
    color: black;
}

@media only screen and (max-width: 576px) {
    .Logo-Text {
        letter-spacing: 5px;
        font-size: 30px;
        font-weight: 200;
    }
    .Logo-Image {
        height: 40px;
    }

}