@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

.AboutMe-Row {
    font-family: "Open Sans";
    background-color: white;
}

.AboutMe1-Image {
    height: 400px;
    width: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    text-align: center;
}

.AboutMe1-Image-Col {
    text-align: center;
}

.AboutMe1-Name {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0px;
}

.AboutMe1-Title {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 41px;
}

.AboutMe-Contact-Me {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.AboutMe-Contact-Me:hover {
    color: white;
    opacity: 80%;
}

@media only screen and (max-width: 600px) {
    .AboutMe1-Image {
        height: 200px;
        width: 200px;
    }
}