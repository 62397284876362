.Button {
    background: #0A1612;
    border-radius: 20px;
    width: 100%;
    color: white;
    border: none;
    font-weight: 200;
    letter-spacing: 2px;
    font-size: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.Button:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #4d4d4d;
    border-color: #4d4d4d;
}

.Button:focus {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    outline: none;
    background: #4d4d4d;
    border: none;
}

.Button-Disabled {
    cursor: not-allowed;
}

.Button-Disabled:hover {
    cursor: not-allowed;
    box-shadow: none;
}
