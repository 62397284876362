.Landing-Spacing {
    padding-top: 60px;
    padding-bottom: 40px;
}

.Landing-Section-Space {
    padding-top: 50px;
    padding-bottom: 30px;
}

.PreviewWebsite-Div {
    padding-top: 100px;
}

.PreviewWebsite-Div-First {
    padding-top: 100px;
}

.PreviewWebsite-Div-Last {
    padding-top: 100px;
    padding-bottom: 100px;
}

.PreviewWebsite-Max-Col {
    max-width: 1300px;
}

@media only screen and (max-width: 576px) {
    .Landing-Spacing {
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .Landing-Section-Space {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .PreviewWebsite-Div {
        padding-top: 80px;
    }

    .PreviewWebsite-Div-First {
        padding-top: 80px;
    }

    .PreviewWebsite-Div-Last {
        padding-top: 80px;
        padding-bottom: 100px;
    }
}