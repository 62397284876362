.Availability-Spots {
    font-size: 12px;
    color: red;
}

.Availability-In-Person {
    font-size: 15px;
    font-weight: 300;
}

.AvailabilityList-Tag {
    border-radius: 10px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
}