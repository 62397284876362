.Testimonial3-Select-Card {
    width: 100%;
    padding: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.Testimonial3-Title {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 34px;
    margin-left: 10px;
}

.Testimonial3-Title-Displayed {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
}


.Testimonial3-Image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.Testimonial3-Pagination {
    text-align: center;
    color: black;
}

.Testimonial3-Text-Div {
    padding: 20px;
}

.Testimonial3-Description {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
}