.UserWorkout-Check {
    font-size: 20px;
    color: green;
}

.UserWorkout-Inst-Checkbox {
}

.UserWorkout-Text-Spacing {
    margin-top: 8px;
    margin-bottom: 8px;
}