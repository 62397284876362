.Membership-Div {
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.Membership-Card-Image {
    width: 100%;
    object-fit: cover;
    height: 250px;
    border-radius: 10px 10px 0px 0px;
}

.Membership-Outer-Col {
    max-width: 1500px;
}

.Membership-Text-Body {
    padding: 20px;
}

.Membership-Card-Name {
    font-size: 22px;
    font-weight: 600;
}

.Membership-Card-Price {
    font-size: 18px;
    font-weight: 400;
}

.Membership-Card-Included {
    font-size: 16px;
    font-weight: 400
}

.Membership-Card-List-Item {
    font-size: 16px;
    font-weight: 400;
}

.Memberships-Description {
    font-size: 15px;
}