@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Landing-Spacing {
    padding-top: 60px;
    padding-bottom: 40px;
}

.Landing-Section-Space {
    padding-top: 50px;
    padding-bottom: 30px;
}

.PreviewWebsite-Div {
    padding-top: 100px;
}

.PreviewWebsite-Div-First {
    padding-top: 100px;
}

.PreviewWebsite-Div-Last {
    padding-top: 100px;
    padding-bottom: 100px;
}

.PreviewWebsite-Max-Col {
    max-width: 1300px;
}

@media only screen and (max-width: 576px) {
    .Landing-Spacing {
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .Landing-Section-Space {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .PreviewWebsite-Div {
        padding-top: 80px;
    }

    .PreviewWebsite-Div-First {
        padding-top: 80px;
    }

    .PreviewWebsite-Div-Last {
        padding-top: 80px;
        padding-bottom: 100px;
    }
}
.Banner1-Main-Col {
    background-color: white;
    font-family: "Open Sans";
}

.Banner1-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    opacity: 0.6;
}

.Banner1-Main-Div {
    background-color: black;
    position: relative;
}

.Banner1-Text-Div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
}

.Banner1-FirstName {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 800;
    font-size: 70px;
    margin-bottom: 10px;
    line-height: 65px;
    word-wrap: break-word;
}

.Banner1-LastName {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 800;
    font-size: 70px;
    margin-bottom: 10px;
    line-height: 65px;
    word-wrap: break-word;
}

.Banner1-BusinessStatement {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    max-width: 700px;
    margin-top: 10px;
    word-wrap: break-word;
}

.Banner1-Input {
    border-radius: 10px;
    padding: 7px;
}

.Banner1-Contact-Button {
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: black;
    color: white;
    height: 100%;
    width: 100%;
    padding: 7px;
}

.Banner1-Contact-Button:hover {
    cursor: pointer;
}

.Banner1-Contact-Section {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .Banner1-Text-Div {
        min-width: 0px;
    }
}


.Banner2-Main-Col {
    background-color: white;
    font-family: "Open Sans";
}

.Banner2-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    opacity: 0.6;
}

.Banner2-Main-Div {
    background-color: black;
    position: relative;
    text-align: center;
}

.Banner2-Text-Div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.Banner2-Name {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 300;
    font-size: 70px;
    line-height: 75px;
    margin-bottom: 10px;
    text-align: center;
    word-wrap: break-word;
}

.Banner2-BusinessStatement {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 300;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    min-width: 700px;
    word-wrap: break-word;
}

@media only screen and (max-width: 600px) {
    .Banner2-BusinessStatement {
        min-width: 0px;
    }
}
.Banner3-Main-Col {
    background-color: white;
    font-family: "Open Sans";
    padding: 20px;
}

.Banner3-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Banner2-Main-Div {
    background-color: black;
    position: relative;
    text-align: center;
}

.Banner2-Text-Div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.Banner3-Name {
    text-align: left;
    color: black;
    font-style: italic;
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.Banner3-BusinessStatement {
    text-align: left;
    color: black;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    word-wrap: break-word;
}

.Banner3-Input {
    border-radius: 10px;
    padding: 7px;
}

.Banner3-Contact-Button {
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: black;
    color: white;
    height: 100%;
    width: 100%;
    padding: 7px;
}

.Banner3-Contact-Button:hover {
    cursor: pointer;
}
.Banner4-Main-Col {
    background-color: white;
    font-family: "Open Sans";
    padding: 20px;
}

.Banner4-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Banner4-Name {
    text-align: left;
    color: black;
    font-style: italic;
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.Banner4-BusinessStatement {
    text-align: left;
    color: black;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    word-wrap: break-word;
}

.Banner4-Input {
    border-radius: 10px;
    padding: 7px;
}

.Banner4-Contact-Button {
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: black;
    color: white;
    height: 100%;
    width: 100%;
    padding: 7px;
}

.Banner4-Contact-Button:hover {
    cursor: pointer;
}

.Banner4-Text-Col {
    padding-left: 30px;
}

@media only screen and (max-width: 600px) {
    .Banner4-Text-Col {
        padding-left: 0px;
    }
    .Banner4-Name {
        font-size: 50px;
    }
    .Banner4-BusinessStatement {
        font-size: 25px;
    }
}
.Navbar1-Main-Row {
    background-color: white;
    padding: 10px;
    font-family: "Open Sans";
}

.Navbar1-Large-Logo {
    max-width: 100px;
    max-height: 60px;
    object-fit: contain;
    margin-right: 10px;
}

.Navbar1-Large-BusinessName {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
}

.Navbar1-Large-Link-Text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: black;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 0px;
    padding-right: 0px;
}

.Navbar1-React-Social-Icon {
    max-height: 25px;
    max-width: 25px;
}

.Navbar1-Large-Login-Button {
    border-radius: 10px;
    background-color: black;
    color: white;
    border-color: black;
    padding: 5px 15px 5px 15px;
}

.Navbar1-Large-Login-Button:hover {
    opacity: 80%;
    cursor: pointer;
}

.Navbar1-Shopping-Cart {
    font-size: 25px;
    color: black;
}

.Navbar-Small-Logo-Name {
    text-align: center;
}

.Navbar1-Small-Logo {
    max-height: 50px;
    max-width: 100%;
}

.Navbar1-Small-BusinessName {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
}

.Navbar-Drawer {
    min-width: 300px;
}

.Navbar-Small-Drawer-Link {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {
    .Navbar1-Large-BusinessName {
        font-size: 13px;
    }

    .Navbar1-Large-Link-Text {
        text-align: center;
        font-size: 15px;
        font-weight: 300;
    }
}
.Navbar2-Main-Row {
    background-color: black;
    padding: 10px;
    font-family: "Open Sans";
}

.Navbar2-Large-Logo {
    max-width: 100px;
    max-height: 60px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
}

.Navbar2-Large-BusinessName {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: white;
}

.Navbar2-Large-Link-Text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: white;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 0px;
    padding-right: 0px;
}

.Navbar2-React-Social-Icon {
    max-height: 25px;
    max-width: 25px;
}

.Navbar2-Large-Login-Button {
    border-radius: 10px;
    background-color: white;
    color: black;
    border-color: white;
    padding: 5px 15px 5px 15px;
    border: none;
}

.Navbar2-Large-Login-Button:hover {
    opacity: 80%;
    cursor: pointer;
}

.Navbar2-Shopping-Cart {
    font-size: 25px;
    color: white
}

.Navbar-Small-Logo-Name {
    text-align: center;
}

.Navbar2-Small-Logo {
    max-height: 50px;
    max-width: 100%;
}

.Navbar2-Small-BusinessName {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
}

.Navbar-Drawer {
    min-width: 300px;
}

.Navbar-Small-Drawer-Link {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {
    .Navbar2-Large-BusinessName {
        font-size: 13px;
    }

    .Navbar2-Large-Link-Text {
        color: black;
    }

    .Navbar2-Shopping-Cart {
        color: black;
    }

    .Navbar2-Large-Login-Button {
        border-radius: 10px;
        background-color: black;
        color: white;
        border: none;
        padding: 5px 15px 5px 15px;
    }

    .Navbar2-Large-Link-Text {
        text-align: center;
        font-size: 15px;
        font-weight: 300;
    }
}
.Navbar3-Main-Col {
    background-color: white;
    padding: 10px;
    font-family: "Open Sans";
}

.Navbar3-Second-Bar {
    background-color: #E9E9E9;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Navbar3-Large-Link-Text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: black;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 0px;
    padding-right: 0px;
}

.Shopping-Cart-Float {
    position:fixed;
    padding: 5px;
	bottom:20px;
	right:20px;
	border-radius:50px;
	text-align:center;
    background-color: white;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Shopping-Cart-Float:hover {
    background-color: white;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.AboutMe-Row {
    font-family: "Open Sans";
    background-color: white;
}

.AboutMe1-Image {
    height: 400px;
    width: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    text-align: center;
}

.AboutMe1-Image-Col {
    text-align: center;
}

.AboutMe1-Name {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0px;
}

.AboutMe1-Title {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 41px;
}

.AboutMe-Contact-Me {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.AboutMe-Contact-Me:hover {
    color: white;
    opacity: 80%;
}

@media only screen and (max-width: 600px) {
    .AboutMe1-Image {
        height: 200px;
        width: 200px;
    }
}
.AboutMe-Row {
    font-family: "Open Sans";
    background-color: white;
}

.AboutMe2-Image {
    height: 50vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
    text-align: center;
    border-radius: 5px;
}

.AboutMe2-Name {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0px;
}

.AboutMe2-Title {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 41px;
}

@media only screen and (max-width: 600px) {
    .AboutMe2-Image {
        height: 60vh;
    }
}
.AboutMe3-Image {
    height: 70vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}
.Gallery1-Image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}

.Service1-Image {
    width: 100%;
    height: 100%;
    max-height: 250px;
    object-position: center;
    object-fit: cover;
    border-radius: 5px;
}

.Service1-Title {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    color: #000000;
}

.Service1-Paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
}

.Service1-Div {
    padding: 20px;
    font-family: "Open Sans";
}

.Service1-Image-Div {
    padding: 10px;
}

.Service-Button {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.Service-Button:hover {
    color: white;
    opacity: 60%;
}
.Service2-Image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.Service2-Image {
    border-radius: 5px 5px 0px 0px;
}

.Service2-Div {
    padding: 20px;
    font-family: "Open Sans";
}

.Service2-Card {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.Service2-Title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.Service2-Description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}
.Service3-Image {
    background-color: black;
    opacity: 70%;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.Service3-Card {
    background-color: black;
    position: relative;
    color: white;
}

.Service3-Text-Div {
    color: white;
    padding: 35px;
    font-family: "Open Sans";
}

.Service3-Title {
    color: white;
    opacity: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}

.Service3-Description {
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
}

.Service-Button-Inverted {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 10px;
}

.Service-Button-Inverted:hover {
    color: black;
    opacity: 60%;
}
.FAQ1-Row {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Open Sans";
}

.FAQ1-Question {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.FAQ1-Question-Container {
    background: #F8F8F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
}

.FAQ1-Answer {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding: 20px;
}
.FAQ2-Row {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Open Sans";
}

.FAQ2-Question {
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.FAQ2-Question-Container {
    padding: 15px;
    cursor: pointer;
}

.FAQ2-Answer {
    background: #FFFFFF;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding-left: 20px;
    padding-right: 20px;
}
.Testimonial1-Image {
    width: 100%;
    height: 100%;
    max-height: 250px;
    object-position: center;
    object-fit: cover;
    border-radius: 5px;
}

.Testimonial1-Title {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    color: #000000;
}

.Testimonial1-Paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
}

.Testimonial1-Div {
    padding: 20px;
    font-family: "Open Sans";
}

.Testimonial1-Image-Div {
    padding: 10px;
}
.Testimonial2-Image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.Testimonial2-Image {
    border-radius: 5px 5px 0px 0px;
}

.Testimonial2-Div {
    padding: 20px;
    font-family: "Open Sans";
}

.Testimonial2-Card {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.Testimonial2-Title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.Testimonial2-Description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}
.Testimonial3-Select-Card {
    width: 100%;
    padding: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.Testimonial3-Title {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 34px;
    margin-left: 10px;
}

.Testimonial3-Title-Displayed {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
}


.Testimonial3-Image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.Testimonial3-Pagination {
    text-align: center;
    color: black;
}

.Testimonial3-Text-Div {
    padding: 20px;
}

.Testimonial3-Description {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
}
.Contact1-Input {
    border-radius: 5px;
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 500;
}

.Contact1-Title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

.Contact1-Contact-Me {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: black;
    color: white;
    border-radius: 10px;
    border: none;
}

.Contact1-Contact-Me:hover {
    color: white;
    opacity: 80%;
    cursor: pointer;
}

.Section-Component-Title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}
.Contact2-Input {
    border-radius: 5px;
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 500;
}

.Contact2-Title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

.Contact2-Contact-Me {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: black;
    color: white;
    border-radius: 10px;
    border: none;
}

.Contact2-Contact-Me:hover {
    color: white;
    opacity: 80%;
    cursor: pointer;
}

.Contact2-Divider {
    color: black;
    background-color: black;
}

.Contact2-HR {
    border-top: 1px solid black;
    background-color: black;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}
.Header-Main-Row {
    margin-top: 20px;
}

.Header-Background {
    background-position: center;
}

.Header-Title {
    margin-bottom: 1px;
}

.Header-Button {
    border-radius: 20px;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
}

.Header-Button:hover {
    border-radius: 20px;
    color: white;
    opacity: 0.6;
}

.Header-Picture {
    width: 100%;
    height: 100%;
    max-width: 400px;

    object-fit: cover;
    border-radius: 10px;
}

.Header-Text-Col {
    padding: 40px;
}

.Header-Image-Col {
    text-align: center;
}

.Header-Input {
    border-radius: 10px;

}

@media only screen and (max-width: 576px) {
    .Header-Picture {
        width: 100%;
        height: 100%;
        max-width: 300px;
        max-height: 350px;
        object-fit: cover;
        border-radius: 10px;
    }
    .Header-Text-Col {
        padding: 40px;
        margin-top: 20px;
    }
    .Header-Main-Row {
        margin-top: 0px;
    }
}
.Logo-Image {
    height: 60px;
    max-height: 100%;
}

.Logo-Text {
    letter-spacing: 5px;
    font-size: 30px;
    font-weight: 200;
    color: black;
}

@media only screen and (max-width: 576px) {
    .Logo-Text {
        letter-spacing: 5px;
        font-size: 30px;
        font-weight: 200;
    }
    .Logo-Image {
        height: 40px;
    }

}
.H3 {
    font-size: 20px;
    font-weight: 300;
}

.H1 {
    font-size: 35px;
    font-weight: 400;
    letter-spacing: 1px;
}

.H2 {
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 1px;
}

.P {
    font-size: 16px;
    font-weight: 300;
}

.Highlight {
    background: linear-gradient(to top, #F7B733 30%, transparent 30%);
}
.Button {
    background: #0A1612;
    border-radius: 20px;
    width: 100%;
    color: white;
    border: none;
    font-weight: 200;
    letter-spacing: 2px;
    font-size: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.Button:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #4d4d4d;
    border-color: #4d4d4d;
}

.Button:focus {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    outline: none;
    background: #4d4d4d;
    border: none;
}

.Button-Disabled {
    cursor: not-allowed;
}

.Button-Disabled:hover {
    cursor: not-allowed;
    box-shadow: none;
}

.Card-Icon {
    font-size: 60px;
}

.Card {
    padding: 20px 20px 30px 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
    background-color: white;
    border-radius: 10px;
}

.Card-Center {
    text-align: center;
}
.SocialMedia-Icon {
    font-size: 25px;
    margin: 2px;
}

.SocialMedia-Icon:hover {
    opacity: 0.7;
}
.Footer-Divider {
    color: black;
    background-color: black;
}

.Footer-Outer-Col {
    padding-top: 60px;
}
.ClassesHeader-Background {
    background-position: center;
}

.ClassesHeader-Image {
    height: 175px;
    width: 175px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ClassesHeader-Col {
    text-align: center;
}

.ClassHeader-Title {
    margin-bottom: 1px;
}
.LiveClass-Image {
    max-width: 100%;
    height: 150px;
    width: 200px;
    object-fit: cover;
    border-radius: 20px;
}

.LiveClass-Row {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px;
    color: black;
    margin-top: 20px;
    margin-bottom: 20px;
    border: solid;
}

.LiveClass-Row:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 576px) {
    .LiveClass-Image {
        height: 200px;
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
}

.Availability-Spots {
    font-size: 12px;
    color: red;
}

.Availability-In-Person {
    font-size: 15px;
    font-weight: 300;
}

.AvailabilityList-Tag {
    border-radius: 10px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
}
.BookLiveClassDetails-Image {
    max-width: 100%;
    width: 300px;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
}
.BookLiveClassPayment-Stripe {
    height: 50px;
    width: 200px;
    object-fit: contain;
}
.Video-Card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    min-height: 400px;
    border-radius: 10px;
    height: 100%;
}

.Video-Card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
}

.Video-Card-Container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Video-Card-Image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

.Video-Card-Button {
    padding-top: 20px;
}

.Video-Card-Modal {
    width: 800px;
}

.Video-Card-Modal-Image {
    min-height: 300px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Video-Card-Tag {
    border-radius: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    margin-bottom: 5px;
}

.Video-Card-Space {
    padding-top: 5px;
    padding-bottom: 5px;
}

.Auth-Google-Image {
    object-fit: contain;
    height: 100%;
    margin-right: 5px;
}
.Video-Card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    min-height: 200px;
    border-radius: 10px;
    height: 100%;
}

.Video-Card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
}

.Video-Card-Container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Video-Card-Image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

.Video-Card-Button {
    padding-top: 20px;
}

.Video-Card-Modal {
    width: 800px;
}

.Video-Card-Modal-Image {
    min-height: 300px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Video-Card-Tag {
    border-radius: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    margin-bottom: 5px;
}

.Video-Card-Space {
    padding-top: 5px;
    padding-bottom: 5px;
}
.PurchaseCard-Container {
    position: relative;
    text-align: center;
    color: white;
    background-color: black;
    font-family: 'Open Sans', sans-serif;
    border-radius: 10px;
    height: 200px;
    width: 100%;
}

.PurchaseCard-Image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    opacity: 0.4;
    object-fit: cover;
    object-position: center;
}

.PurchaseCard-Container:hover {
    opacity: 0.8;
}

.PurchaseCard-Bottom-Left {
    position: absolute;
    bottom: 3px;
    left: 10px;
}

.PurchaseCard-Title {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: white;
    text-align: left;
}

.PurchaseCard-Description {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: white;
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .PurchaseCard-Title {
        font-size: 12px;
    }

    .PurchaseCard-Description {
        font-size: 10px;
    }

    .PurchaseCard-Container {
        height: 150px;
    }
}
.Bootcamp-LiveClass {

}
.AboutMe-Image {
    max-width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
}

.AboutMe-Div {
    background: #FFFFFF;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 20px;
}

.AboutMe-Padding {
    padding: 20px;
}

p {
    font-size: 17px;
}
.FAQ-Collapse-Custom {
    font-size: 20px;
}

.FAQ-Collapse-Custom-Text {
    font-size: 15px;
}

.FAQ-Collapse {
    border-radius: 20px;
}
.Video-Card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    min-height: 400px;
    border-radius: 10px;
    height: 100%;
}

.Video-Card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
}

.Video-Card-Container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Video-Card-Image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

.Video-Card-Button {
    padding-top: 20px;
}

.Video-Card-Modal {
    width: 800px;
}

.Video-Card-Modal-Image {
    min-height: 300px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Video-Card-Tag {
    border-radius: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    margin-bottom: 5px;
}

.Video-Card-Space {
    padding-top: 5px;
    padding-bottom: 5px;
}
.CarouselCard-Phros-Div {
    max-height: 300px;
    width: 100%;
    border-radius: 10px;
}

.CarouselCard-Image {
    height: 150px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.CarouselCard-Button {
    border-radius: 10px;
    color: white;
    background-color: black;
    margin: 5px;
    border: black;
}

.CarouselCard-Text {
    padding: 5px;
}
.SimpleTextCard-Container {
    position: relative;
    text-align: center;
    color: black;
    text-align: center;
    width: 100%;
    min-height: 275px;
    border-radius: 10px;
    font-family: "Open Sans";
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.SimpleTextCard-Center-Text {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.SimpleTextCard-Title {
    text-align: center;
    color: white;
    font-size: 35px;
    font-weight: 800;
    width: 80vw;
}

.SimpleTextCard-Description {
    text-align: center;
    color: white;
    font-size: 25px;
    width: 80vw;
}

@media only screen and (max-width: 600px) {
    .SimpleTextCard-Title {
        text-align: center;
        color: white;
        font-size: 20px;
        font-weight: 800;
    }
    .SimpleTextCard-Description {
        text-align: center;
        color: white;
        font-size: 18px;
        width: 80vw;
    }

}

.LiveClassPage-AvailabilityList {
    margin-top: 30px;
}


.CheckoutCard-Image {
    min-height: 150px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.Checkout-Total-Cost {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 20px;
    border-radius: 20px;
}

.Checkout-Row {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Checkout-Input {
    border-radius: 10px;
}

.Checkout-Button {
    border-radius: 10px;
    background-color: black;
    color: white;
}

.Checkout-Button:active:focus:hover {
    border-radius: 10px;
    background-color: black;
    color: white;
}

.Checkout-Promo-Applied-Products {
    font-size: 13px;
}

.Checkout-Not-Charged-Yet {
    font-size: 13px;
    text-align: center;
    margin-top: 5px;
}
.CustomerFitness-Collapse-Panel {
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
}

.Customer-Fitness-Main-Div {
    font-family: "Open Sans";
}

.CustomerFitness-Card {
    height: 225px;
    width: 100%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    border-radius: 20px;
    background-color: white;
}

.CustomerFitness-Card:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    background-color: rgb(235, 235, 235);
}


.CustomerFitness-Card-Title {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    font-weight: bold;
}

.Customer-Fitness-Section-Title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
}

.Customer-Fitness-PurchaseCard-Row {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    .Customer-Fitness-Section-Title {
        font-size: 15px;
    }
}
.UserWorkout-Check {
    font-size: 20px;
    color: green;
}

.UserWorkout-Inst-Checkbox {
}

.UserWorkout-Text-Spacing {
    margin-top: 8px;
    margin-bottom: 8px;
}

.UserProgram-Badge {
    height: 50px;
}

.UserProgram-Button-Completed {
    background-color: green;
    color: white;
    border-radius: 20px;
}

.UserProgram-Day-Complete-Div {
    line-height: 100%;
    height: 100%;
    text-align: center;
}

.UserProgram-Day-Complete {
    font-size: 35px;
    color: green;
    display: inline-block;
    vertical-align: middle;
}

.ProgramWorkout-Exercise-Video {
    width: 100%;
    min-height: 300px;
    object-position: center;
    object-fit: cover;
}

.UserProgram-Save-Workout {
    background-color: black;
    color: white;
    border-radius: 10px;
}

.ProgramWorkout-Exercise {
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
}

.ProgramWorkout-ExerciseDetails-Button {
    background-color: black;
    color: white;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ProgramWorkout-Modal-Content {
    margin-top: 20px;
}
.Membership-Div {
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.Membership-Card-Image {
    width: 100%;
    object-fit: cover;
    height: 250px;
    border-radius: 10px 10px 0px 0px;
}

.Membership-Outer-Col {
    max-width: 1500px;
}

.Membership-Text-Body {
    padding: 20px;
}

.Membership-Card-Name {
    font-size: 22px;
    font-weight: 600;
}

.Membership-Card-Price {
    font-size: 18px;
    font-weight: 400;
}

.Membership-Card-Included {
    font-size: 16px;
    font-weight: 400
}

.Membership-Card-List-Item {
    font-size: 16px;
    font-weight: 400;
}

.Memberships-Description {
    font-size: 15px;
}
.ProgramOverview-Main-Div {
    max-width: 100vw;
}

.ProgramOverview-Row {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ProgramOverview-Input {
    border-radius: 10px;
    width: 100%;
}

.ProgramOverview-Input-Subtext {
    font-size: 13px;
}

.ProgramOverview-Button {
    background-color: black;
    border-color: black;
    color: white;
    border-radius: 10px;
}

.ProgramOverview-Calendar-Workout-Button {
    background-color: black;
    border-color: black;
    color: white;
    border-radius: 10px;
    height: 40px;
}

.ProgramOverview-Calendar-Workout-Button:active:hover {
    background-color: black;
    border-color: black;
    color: white;
    border-radius: 10px;
    height: 40px;
}

.ProgramOverview-Button-Complete:active:hover {
    background-color: #39C16C;
    border-color:  #39C16C;
    border-radius: 10px;
}
.ProgramWorkout-Main-Div {
    max-width: 100vw;
}

.ProgramWorkout-Exercise-Link {
    padding-top: 5px;
    padding-bottom: 5px;
}

.ProgramOverview-Button-Danger {
    border-radius: 10px;
}

.ProgramOverview-Button-Complete {
    background-color: #39C16C;
    border-color:  #39C16C;
    border-radius: 10px;
}
.ProgramExercise-Video {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    border: none;
}
