.H3 {
    font-size: 20px;
    font-weight: 300;
}

.H1 {
    font-size: 35px;
    font-weight: 400;
    letter-spacing: 1px;
}

.H2 {
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 1px;
}

.P {
    font-size: 16px;
    font-weight: 300;
}

.Highlight {
    background: linear-gradient(to top, #F7B733 30%, transparent 30%);
}