.SimpleTextCard-Container {
    position: relative;
    text-align: center;
    color: black;
    text-align: center;
    width: 100%;
    min-height: 275px;
    border-radius: 10px;
    font-family: "Open Sans";
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.SimpleTextCard-Center-Text {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.SimpleTextCard-Title {
    text-align: center;
    color: white;
    font-size: 35px;
    font-weight: 800;
    width: 80vw;
}

.SimpleTextCard-Description {
    text-align: center;
    color: white;
    font-size: 25px;
    width: 80vw;
}

@media only screen and (max-width: 600px) {
    .SimpleTextCard-Title {
        text-align: center;
        color: white;
        font-size: 20px;
        font-weight: 800;
    }
    .SimpleTextCard-Description {
        text-align: center;
        color: white;
        font-size: 18px;
        width: 80vw;
    }

}