.Service2-Image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.Service2-Image {
    border-radius: 5px 5px 0px 0px;
}

.Service2-Div {
    padding: 20px;
    font-family: "Open Sans";
}

.Service2-Card {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.Service2-Title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.Service2-Description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}