.Header-Main-Row {
    margin-top: 20px;
}

.Header-Background {
    background-position: center;
}

.Header-Title {
    margin-bottom: 1px;
}

.Header-Button {
    border-radius: 20px;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
}

.Header-Button:hover {
    border-radius: 20px;
    color: white;
    opacity: 0.6;
}

.Header-Picture {
    width: 100%;
    height: 100%;
    max-width: 400px;

    object-fit: cover;
    border-radius: 10px;
}

.Header-Text-Col {
    padding: 40px;
}

.Header-Image-Col {
    text-align: center;
}

.Header-Input {
    border-radius: 10px;

}

@media only screen and (max-width: 576px) {
    .Header-Picture {
        width: 100%;
        height: 100%;
        max-width: 300px;
        max-height: 350px;
        object-fit: cover;
        border-radius: 10px;
    }
    .Header-Text-Col {
        padding: 40px;
        margin-top: 20px;
    }
    .Header-Main-Row {
        margin-top: 0px;
    }
}