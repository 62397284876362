.AboutMe-Image {
    max-width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
}

.AboutMe-Div {
    background: #FFFFFF;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 20px;
}

.AboutMe-Padding {
    padding: 20px;
}

p {
    font-size: 17px;
}